<template>
    <div id="dashboard">
        <DashboardHeader>
            <div id="year-selector-group" class="order-1 order-lg-0 mt-3 mt-lg-0">
                <YearSelector title="Zvolte srovnávaný rok:" id="selyear" :selectedIndex="-1" class="mb-2 mb-lg-0" />
                <YearSelector title="Zvolte referenční rok:" id="refyear" :selectedIndex="-2"/>
            </div>
        </DashboardHeader>

        <div class="d-flex flex-column flex-lg-row px-2 mr-lg-2">
            <div class="d-flex flex-column container-fluid px-0 col-lg-8 mr-lg-2" style="min-height: 100%;">
                <div class="h-100 mb-2">
                    <GasGraphTile id="gas-use-chart" graphTitle="Skutečná a modelová spotřeba plynu"
                        yAxisTitle="Denní spotřeba plynu (m<sup>3</sup>)" :graphData="gasUseData" />
                </div>
                <div class="h-100">
                    <GasGraphTile id="gas-save-chart" graphTitle="Kumulativní úspora plynu"
                        yAxisTitle="Celková úspora/ztráta (m<sup>3</sup>)" yAxis2Title="Denní úspora/ztráta (m<sup>3</sup>)"
                        :graphData="gasSaveData" />
                </div>
            </div>
            <div class="d-flex flex-column container-fluid px-0 mt-2 mt-lg-0 col-lg-4 mr-lg-2" style="min-height: 100%;">
                <GasSavingsGroupCard title="Úspora za zvolenou topnou sezónu" :valueMon="totmon" :valueCo2="totco2" class="h-100 mb-2" />
                <GasSavingsGroupCard title="Úspora za poslední týden" :valueMon="weekmon" :valueCo2="weekco2" class="h-100 mb-2" />
                <GasSavingsGroupCard title="Úspora za poslední den" :valueMon="daymon" :valueCo2="dayco2" class="h-100" />
                
            </div>
        </div>

        <DashboardFooter />
    </div>
</template>

<script>
import { eventBus } from '../store.js'

import DashboardHeader from './DashboardHeader.vue'
import DashboardFooter from './DashboardFooter.vue'

import YearSelector from './YearSelector.vue'

import GasGraphTile from './GasGraphTile.vue'
import GasSavingsGroupCard from './GasSavingsGroupCard.vue'

export default {
    name: 'Dashboard',
    components: {
        DashboardHeader,
        DashboardFooter,
        YearSelector,
        GasGraphTile,
        GasSavingsGroupCard
    },
    data() {
        return {
            // First set all to null, since we technically don't know, what years are selected.

            gasUseData: [
                {
                    name: "skutečná spotřeba",

                    x: null,
                    y: null,

                    type: 'bar',
                    width: 1000 * 3600 * 16,

                    marker: {
                        color: 'rgb(161, 222, 144)'
                    }
                },

                {
                    name: "modelová spotřeba",

                    x: null,
                    y: null,

                    type: 'scatter',
                    mode: 'lines',

                    line: {
                        color: 'rgb(250, 110, 79)',
                        width: 1.5,

                        shape: 'hv' // Or 'vh' ? or smoothing or ... https://plotly.com/javascript/reference/#scatter-line-shape
                    }
                }
            ],

            gasSaveData: [
                {
                    name: 'celková úspora/ztráta',

                    x: null,
                    y: null,
                    yaxis: 'y',

                    type: 'bar',
                    width: 1000 * 3600 * 16,

                    marker: {
                        color: null,
                        cmin: -1,
                        cmax: 1,
                        colorscale: [[0, 'rgba(250, 110, 79, 0.5)'], [1, 'rgb(161, 222, 144)']]
                    }
                },

                {
                    name: 'denní úspora/ztráta',

                    x: null,
                    y: null,
                    yaxis: 'y2',

                    type: 'scatter',
                    mode: 'lines',

                    line : {
                        color: 'rgb(151, 132, 209)',
                        width: 1.5,

                        shape: 'hv' // See ^^^^
                    }
                }
            ],

            totmon: null,
            totco2: null,
            weekmon: null,
            weekco2: null,
            daymon: null,
            dayco2: null
        }
    },

    methods: {
        createCustomLegend() {
            let legendComp = document.getElementById('gas-save-chart').getElementsByClassName('legendpoints')[0]
            if(legendComp != undefined) {
                legendComp.innerHTML = '<g transform="translate(14,-6)"><path style="fill:#a1de90;fill-opacity:1;stroke:none" d="M 0,0 H 12 V 12 H 0 Z" /><path style="fill:#fa6e4f;fill-opacity:1;stroke:none" d="M 12,12 H 0 L 12,0 Z"/></g>'
            }
        }
    },

    created: function() {
        eventBus.$on('onYearSelectionChange', () => {
            if(this.$store.state.selyear != null && this.$store.state.refyear != null) {
                this.getGasUse(this.$store.state.selyear, this.$store.state.refyear)
                this.getGasSave(this.$store.state.selyear, this.$store.state.refyear)
                this.getTotals(this.$store.state.selyear, this.$store.state.refyear)
            }
        })

        // Handlers of asynchronous data fetching

        eventBus.$on('onGasUseRetrieved', (gasUseData) => {
            var x = []

            var yReal = []
            var yModel = []

            // First, format the data
            for(var i = 0; i < gasUseData.length; i++) {
                x.push(gasUseData[i].day)

                yReal.push(gasUseData[i].gasvol)
                yModel.push(gasUseData[i].refgasvol)
            }

            // Then insert the data into the charts
            this.gasUseData[0].x = x
            this.gasUseData[0].y = yReal

            this.gasUseData[1].x = x
            this.gasUseData[1].y = yModel
        })

        eventBus.$on('onGasSaveRetrieved', (gasSaveData) => {
            var x = []

            var yTotal = []
            var yTotalColorSpace = []
            var yDaily = []

            // First, format the data + generate the color space
            for(var i = 0; i < gasSaveData.length; i++) {
                x.push(gasSaveData[i].day)

                yTotal.push(gasSaveData[i].cumval)
                yTotalColorSpace.push((gasSaveData[i].cumval >= 0) ? 1 : -1)

                yDaily.push(gasSaveData[i].val)
            }

            // Then insert the data into the charts
            this.gasSaveData[0].x = x
            this.gasSaveData[0].y = yTotal
            this.gasSaveData[0].marker.color = yTotalColorSpace

            this.gasSaveData[1].x = x
            this.gasSaveData[1].y = yDaily

            this.createCustomLegend()
        })

        eventBus.$on('onTotalsRetrieved', (totals) => {
            // Simply insert data
            this.totmon = totals.totmon
            this.totco2 = totals.totco2
            this.weekmon = totals.weekmon
            this.weekco2 = totals.weekco2
            this.daymon = totals.daymon
            this.dayco2 = totals.dayco2
        })

        // Add dynamic data updating
        setInterval(() => {
            var d = new Date()
            console.log('Running dynamic data update. (time: ' + d.toLocaleTimeString() + ')')

            eventBus.$emit('onYearSelectionChange')

        }, this.dynamicDataUpdateInterval)
    }
}
</script>