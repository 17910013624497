<template>
    <div class="d-flex flex-column text-center border bg-white rounded shadow-sm">
        <div class="w-100 pt-2 graph-card-header">
            <span class="h5">{{graphTitle}}</span>
        </div>
        
        <!-- :responsive="true" breaks for some reason..? https://stackoverflow.com/questions/65009862/ -->
        <div class="mt-2 px-3">
            <Plotly class="plotly-container" :data="graphData" :layout="graphLayout"
                :modeBarButtonsToRemove="graphButtonRemoveList" :displaylogo="false"/>
        </div>
    </div>
</template>

<script>
import { Plotly } from 'vue-plotly'
import { eventBus } from '../store'

export default {
    name: 'GasGraphTile',

    components: {
        Plotly
    },

    props: {
        graphTitle: {
            type: String
        },

        graphData: {
            type: Array,
            required: true
        },

        yAxisTitle: {
            type: String
        },

        yAxis2Title: {
            type: String
        }
    },

    methods: {
        chartFitScreen() {
            // If not mobile view
            if(window.innerWidth >= 992) {
                let vh = window.innerHeight

                let els = document.getElementsByClassName('svg-container')
                for(var i = 0; i < els.length; i++) {
                    // Magic number... Really don't like this...
                    let dim = (vh - 226.274) / 2
                    // For screens shorter than 575px, plotly cannot draw a proper chart...
                    // In this case I think, we should make it rather scrollable than unwatchable.
                    let minDim = 174.363

                    els[i].style.height = Math.max(dim, minDim).toFixed(2) + "px"
                }
            }
        }
    },

    data() {
        return {
            graphLayout: {
                margin: {
                    l: 25,
                    r: (this.yAxis2Title != null) ? 25 : 52, // TODO: Hardcoded fix of yaxis2 problem
                    t: 10,
                    b: 20
                },

                yaxis: {
                    title: {
                        text: this.yAxisTitle,
                        font: {
                            family: 'Mukta'
                        }
                    },
                    automargin: true,

                    zeroline: false
                },
                yaxis2: {
                    title: {
                        text: this.yAxis2Title,
                        font: {
                            family: 'Mukta'
                        }
                    },
                    automargin: true,

                    overlaying: 'y',
                    side: 'right',

                    showgrid: false,
                    zeroline: false
                },

                showlegend: true,
                legend: {
                    x: 0.5,
                    y: -0.1,
                    xanchor: 'center',
                    orientation: 'h',

                    itemclick: false, // TODO Not sure I want to turn these off.
                    itemdoubleclick: false,

                    font: {
                        family: 'Mukta'
                    }
                },

                hoverlabel: {
                    namelength: -1,
                    font: {
                        family: 'Mukta'
                    }
                }
            },

            graphButtonRemoveList: ['toggleSpikelines', 'select2d', 'lasso2d']
        }
    },

    // For mock db access and actual RESTful API communication, the lifcycle events are a little different.
    // When creating the custom legend, we have to account for both. That's why there are two handlers.

    // There are about three too many calls of the resize method, but
    // I couldn't figure out how to do it differently.
    // Sometimes, Vue is just weird. Or I am. Who knows...

    mounted: function() {
        this.chartFitScreen()
        window.addEventListener('resize', this.chartFitScreen)
    },

    created: function() {
        eventBus.$on('onGasSaveRetrieved', (data) => {
            this.createCustomLegend()

            // Because of the delay of the RESTful API response, plotly chart changes
            // it's dimensions at a "random" time after this component is created.
            // No other solution could be thought of to solve this, other than
            // waiting a bit and than hoping the data has been retrieved.
            // (TODO:) Shall come back to this later and try to make it more Vue-like
            setTimeout(() => {
                this.chartFitScreen()
            }, 100)
        })
    }
}
</script>