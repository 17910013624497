<template>
    <div class="d-flex flex-column text-center bg-white border rounded shadow-sm">
        <div class="w-100 pt-2 pb-2 px-3 totals-card-header">
            <span class="h5" v-html="title"></span>
        </div>

        <div class="d-flex w-100 h-100 totals-card-body">
            <div class="d-flex flex-column justify-content-center p-3 pr-4 h-100 w-100" 
            :style="'background-color: ' + ((this.valueMon > 0) ? this.bgGreen : ((this.valueMon < 0) ? this.bgRed : 'white'))">
                <span class="d-block saving-value">{{(valueMon != null) ? this.formatValue(valueMon) : 'N/A'}}</span>
                <span class="d-block text-muted value-unit">Kč</span>
            </div>
            <div class="d-flex flex-column justify-content-center p-3 pl-4 h-100 w-100"
            :style="'background-color: ' + ((this.valueCo2 > 0) ? this.bgGreen : ((this.valueCo2 < 0) ? this.bgRed : 'white'))">
                <span class="d-block saving-value">{{(valueCo2 != null) ? this.formatValue(valueCo2) : 'N/A'}}</span>
                <span class="d-block text-muted value-unit">kg CO&#8322;</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GasSavingsGroupCard',

    props: {
        title: {
            type: String
        },

        valueMon: {
            type: Number
        },

        valueCo2: {
            type: Number
        }
    },

    data() {
        return {
            bgRed: 'rgb(250 110 79 / 35%)',
            bgGreen: 'rgb(161 222 144 / 35%)'
        }
    },

    methods: {
        formatValue(x) {
            return Math.round(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
    }
}
</script>