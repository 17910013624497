<template>
    <div class="d-flex d-md-inline justify-content-between align-items-center mr-md-2">
        <span class="d-inline mr-1 mb-0 h5 align-middle">{{title}}</span>
        <b-form-select size="sm" class="mr-2 ml-1" v-model="selectedYear" :options="allYears" @input="inputChanged"></b-form-select>
    </div>
</template>

<script>
import { eventBus } from '../store.js'

export default {
    name: 'YearSelector',
    props: {
        title: {
            required: true,
            type: String
        },

        id: {
            required: true,
            type: String
        },

        selectedIndex: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            seasons: [],

            allYears: [],
            selectedYear: null
        }
    },
    created: function() {

        let alreadyRetrieved = false

        eventBus.$on('onYearSelectionChange', () => {
            // Disable the year, that the other selector has selected. DEBUGGING REQUIRED?
            for(var i = 0; i < this.seasons.length; i++) {
                let disabled = false;

                if((this.id === 'refyear' && this.$store.state.selyear === this.seasons[i]) ||
                    (this.id === 'selyear' && this.$store.state.refyear === this.seasons[i])) {
                    disabled = true
                }

                this.allYears.find(o => { return o.value === this.seasons[i] }).disabled = disabled
            }
        })

        eventBus.$on('onSeasonsRetrieved', (seasonList) => {
            if(!alreadyRetrieved) {
                this.seasons = seasonList

                // Create an options object. Doing it this way, so that later certain options can be disabled.
                for(var i = 0; i < this.seasons.length; i++) {
                    this.allYears.push({'value': this.seasons[i], 'text': this.seasons[i], 'disabled': false})
                }

                this.selectedYear = this.seasons[this.seasons.length + this.selectedIndex]

                // Emit the first input change in order to grey out the selected years of the other input and allow charts to draw
                this.inputChanged()

                alreadyRetrieved = true
            }
        })

        this.getSeasons()
    },
    methods: {
        inputChanged() {
            
            // Store the value of selected year
            if(this.id === 'refyear') {
                this.$store.commit('setRefYear', this.selectedYear)
            } else if (this.id === 'selyear') {
                this.$store.commit('setSelYear', this.selectedYear)
            }

            // Fire selection change event; possible TODO: would passing any parameters help reduce the complicated/non-optimal event handler?
            eventBus.$emit('onYearSelectionChange')
        }
    }
}
</script>